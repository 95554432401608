import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import {
  RemoveStudentSessionAction,
  SetStudentSessionAction
} from './student-session.action';

export interface StudentSessionStateModel {
  id: number | null;
  name: string | null;
  pass: string | null;
  token: string | null;
}

export const STUDENT_SESSION_TOKEN = new StateToken<StudentSessionStateModel>(
  'studentSession'
);

@State<StudentSessionStateModel>({
  name: STUDENT_SESSION_TOKEN,
  defaults: {
    id: null,
    name: null,
    pass: null,
    token: null
  }
})
@Injectable()
export class StudentSessionState {
  private readonly router = inject(Router);

  @Selector() static token({ token }: StudentSessionStateModel) {
    return token;
  }
  @Selector() static student({ id, pass, name }: StudentSessionStateModel) {
    return { id, pass, name };
  }

  @Action(SetStudentSessionAction) setStudentSession(
    { patchState }: StateContext<StudentSessionStateModel>,
    { payload }: SetStudentSessionAction
  ) {
    patchState({
      id: payload.id,
      name: payload.name,
      token: payload.token,
      pass: payload.pass
    });
  }

  @Action(RemoveStudentSessionAction) removeStudentSession({
    patchState
  }: StateContext<StudentSessionStateModel>) {
    patchState({
      id: null,
      name: null,
      token: null
    });
    this.router.navigate(['ciclos', 'buscar-aluno']);
  }
}
