import { SetStudentSessionPayload } from './student-session.model';

export class SetStudentSessionAction {
  static readonly type = '[StudentSession] Set Session';
  constructor(public payload: SetStudentSessionPayload) {}
}

export class RemoveStudentSessionAction {
  static readonly type = '[StudentSession] Remove Session';
}
